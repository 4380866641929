import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, NavLink, Redirect, withRouter } from 'react-router-dom';
import { Site, RouterContextProvider, Error404Page } from 'tabler-react';

// import Home from '../pages/Home';
import Brands from '../pages/Brands/Brands';
import BrandPage from '../pages/Brands/BrandPage';
import BackendUsers from '../pages/Users/BackendUsers';
import BackendUser from '../pages/Users/BackendUser';
import AddLottery from '../pages/Lottery/AddLottery';
import LotteryList from '../pages/Lottery/LotteryList';
import LobbyVersions from '../Lobbies/LobbyVersions';
import LotteryEditPage from '../pages/Lottery/LotteryEditPage';
import ProgressiveJackpotFundTransactionsList from '../pages/ProgressiveJackpotFundTransactions/ProgressiveJackpotFundTransactionsList';
import AddBrandPage from '../pages/Brands/AddBrandPage';
import AddBackendUserPage from '../pages/Users/AddBackendUserPage';
import Draws from '../pages/Draws/Draws';
import DrawsManual from '../pages/DrawsManual/DrawsManual';
import DrawPage from '../pages/Draws/DrawPage';
import WinningsReport from '../pages/Reports/WinningsReport';
import InstantWinningsReport from '../pages/Reports/InstantWinningsReport';
import SalesReport from '../pages/Reports/SalesReport';
import DrawsReport from '../pages/Reports/DrawsReport';
import PlayersReport from '../pages/Reports/PlayersReport';
import TransactionsReport from '../pages/Reports/TransactionsReport';
import Bets from '../pages/Bets';
import BetPage from '../pages/Bet';
import Dashboard from '../pages/Dashboard';
import Tickets from '../pages/Tickets';
import Player from '../pages/Player/Player';
import Lobbies from '../Lobbies/Lobbies';
import Lobby from '../Lobbies/Lobby';
import LobbyEdit from '../Lobbies/LobbyEdit';
import AddLobby from '../Lobbies/AddLobby/AddLobby';
import Notification from '../Reusable/Notification/Notification';
import OutboundChannels from '../pages/Configuration/OutboundChannels/OutboundChannels';
import OutboundChannel from '../pages/Configuration/OutboundChannels/OutboundChannel';
import Provider from '../pages/Providers/ProvidersPage';
import ProviderFormPage from '../pages/Providers/ProviderFormPage';
import SystemPage from '../pages/System/SystemPage';
import PlayersPage from '../pages/Players/PlayersPage';
import ProviderDraws from '../pages/ProviderDraws/ProviderDraws';
import ProviderDraw from '../pages/ProviderDraws/ProviderDraw';
import PromotionsPage from '../pages/Promotions/PromotionsPage';
import PromotionPage from '../pages/Promotions/PromotionPage';
import GlobalContent from '../GlobalContent/GlobalContent';
import AllModals from '../modals/AllModals';

import { logout } from '../../actions/authActions';
import { getSystemSettings } from '../../actions/systemSettings';

import { userRoles } from '../../utils/roles';

import logo from '../../assets/images/logo-21.png';
import userAvatar from '../../assets/images/user.png';

import './LoggedLayout.scss';

const navBarItemsAll = [
  {
    value: 'Dashboard',
    icon: 'pie-chart',
    to: '/dashboard',
    LinkComponent: NavLink,
    userRoles: [userRoles.superAdmin, userRoles.admin],
  },
  {
    value: 'Ticket Reports',
    icon: 'trending-up',
    to: '/reports/bets',
    LinkComponent: withRouter(NavLink),
    userRoles: [userRoles.support],
  },
  {
    value: 'Reports',
    icon: 'trending-up',
    userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator, userRoles.reportsManager],
    subItems: [
      {
        value: 'Winnings Reports',
        to: '/reports/winnings-report',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin, userRoles.support],
      },
      {
        value: 'Instant Winnings Reports',
        to: '/reports/instant-winnings-report',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin, userRoles.support],
      },
      {
        value: 'Sales Reports',
        to: '/reports/sales-report',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator],
      },
      {
        value: 'Draws Reports',
        to: '/reports/draws-report',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator],
      },
      {
        value: 'Players Reports',
        to: '/reports/players-report',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator],
      },
      {
        value: 'Transactions Reports',
        to: '/reports/transactions-report',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator],
      },
    ],
  },
  {
    value: 'Lotteries',
    icon: 'star',
    to: '/lottery',
    LinkComponent: withRouter(NavLink),
    userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.reportsManager],
  },
  {
    value: 'Draws',
    icon: 'box',
    userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator],
    subItems: [
      {
        value: 'Game Draws',
        to: '/draws',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator],
      },
      {
        value: 'Provider Draws',
        to: '/provider-draws',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.resultsOperator],
      },
    ],
  },
  {
    value: 'Promotions',
    icon: 'dollar-sign',
    to: '/promotions',
    LinkComponent: withRouter(NavLink),
    userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator],
  },
  {
    value: 'Tickets',
    icon: 'dollar-sign',
    userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator, userRoles.support, userRoles.reportsManager],
    subItems: [
      {
        value: 'Tickets',
        to: '/tickets',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator],
      },
      {
        value: 'Instant Tickets',
        to: '/instant-tickets',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator],
      },
    ],
  },
  {
    value: 'Brands',
    icon: 'briefcase',
    to: '/brands',
    LinkComponent: withRouter(NavLink),
    userRoles: [userRoles.superAdmin, userRoles.admin],
  },
  {
    value: 'Providers',
    icon: 'check',
    userRoles: [userRoles.superAdmin, userRoles.admin],
    subItems: [
      {
        value: 'Outbound Channels',
        to: '/configuration/channels',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin, userRoles.support],
      },
      {
        value: 'BtoBet',
        to: '/providers/btobet',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin],
      },
      {
        value: 'ILS Player Integrator',
        to: '/providers/ils-player-integrator',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin],
      },
      {
        value: 'Tola',
        to: '/providers/tola',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin],
      },
      {
        value: 'Editec',
        to: '/providers/editec',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin],
      },
      {
        value: 'Ltech',
        to: '/providers/ltech',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin],
      },
      {
        value: 'Singular',
        to: '/providers/singular',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin],
      },
      {
        value: 'Standalone',
        to: '/providers/standalone',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin],
      },
      {
        value: 'RNG',
        to: '/providers/rng',
        LinkComponent: withRouter(NavLink),
        userRoles: [userRoles.superAdmin],
      },
    ],
  },
  {
    value: 'Users',
    icon: 'package',
    to: '/backend-users',
    LinkComponent: withRouter(NavLink),
    userRoles: [userRoles.superAdmin, userRoles.admin],
  },
  {
    value: 'Lobbies',
    icon: 'map',
    to: '/lobbies',
    LinkComponent: withRouter(NavLink),
    userRoles: [userRoles.superAdmin, userRoles.admin, userRoles.brand, userRoles.resultsOperator, userRoles.lobbyManager],
  },
  {
    value: 'Players',
    icon: 'user',
    to: '/players',
    LinkComponent: withRouter(NavLink),
    userRoles: [userRoles.superAdmin],
  },
  {
    value: 'System',
    icon: '',
    to: '/system',
    LinkComponent: withRouter(NavLink),
    userRoles: [userRoles.superAdmin],
  },
];

class LoggedLayout extends React.Component {
  componentDidMount() {
    const { getSystemSettings } = this.props;

    getSystemSettings();
  }

  getIntersection(arr1, arr2) {
    return arr1.filter((item) => arr2.indexOf(item) !== -1);
  }

  getAllowedNavBarItems() {
    if (!this.props.userRoles) {
      return;
    }

    return navBarItemsAll.filter((item) => this.getIntersection(item.userRoles, this.props.userRoles).length > 0);
  }

  renderRedirectPath() {
    const { user } = this.props;
    const isSupOperator = user.roles.includes(userRoles.support);
    const isLobbyManager = user.roles.includes(userRoles.lobbyManager);
    const isResultOperator = user.roles.includes(userRoles.resultsOperator);

    if (isLobbyManager) {
      return '/lobbies';
    }

    if (isResultOperator) {
      return '/draws';
    }

    if (isSupOperator) {
      return '/reports/bets';
    }

    return '/draws';
  }

  render() {
    const navBarItems = this.getAllowedNavBarItems();
    const { user, logout, openNotification } = this.props;
    const userRole = user.roles ? user.roles[0].split('_').join(' ') : '';
    const upperCaseUserRole = userRole && userRole.charAt(0).toUpperCase() + userRole.slice(1);
    const accountDropdownProps = {
      name: `${user.firstName} ${user.lastName}`,
      description: upperCaseUserRole,
      avatarURL: userAvatar,
      options: [
        // { icon: 'user', value: 'Profile', to: `/backend-users/${user.id}` },
        // { isDivider: true },
        { icon: 'log-out', value: 'Sign out', onClick: () => logout() },
      ],
    };

    return (
      <div className="logged-layout">
        <Site.Wrapper
          headerProps={{
            href: '/',
            alt: '',
            imageURL: logo,
            accountDropdown: accountDropdownProps,
          }}
          navProps={{ itemsObjects: navBarItems }}
          routerContextComponentType={withRouter(RouterContextProvider)}
        >
          <GlobalContent />
          <div className="container">
            <Switch>
              <Route exact path="/brands" component={Brands} />
              <Route exact path="/brands/add" component={AddBrandPage} />
              <Route exact path="/brands/:id" component={BrandPage} />
              <Route exact path="/backend-users" component={BackendUsers} />
              <Route exact path="/backend-users/add" component={AddBackendUserPage} />
              <Route exact path="/backend-users/:id" component={BackendUser} />
              <Route exact path="/lottery/add" component={AddLottery} />
              <Route exact path="/lottery" component={LotteryList} />
              <Route exact path="/lottery/lotteries/:id" component={LotteryEditPage} />
              <Route
                exact
                path="/progressive-jackpot-fund-transactions/:id"
                component={ProgressiveJackpotFundTransactionsList}
              />
              <Route exact path="/draws-manual/:lotteryId" component={DrawsManual} />
              <Route exact path="/draws" component={Draws} />
              <Route exact path="/draws/:id" component={DrawPage} />
              <Route exact path="/draws/bets/:id" component={Bets} />
              <Route
                exact
                path="/draws/bet/:id"
                render={() => <BetPage type="default" pageTitle="Ticket/Bet Slip" />}
              />
              <Route
                exact
                path="/draws/instant-bet/:id"
                render={() => <BetPage type="instant" pageTitle="Instant Ticket/Bet Slip" />}
              />
              <Route exact path="/provider-draws" component={ProviderDraws} />
              <Route exact path="/provider-draws/:id" component={ProviderDraw} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/reports/sales-report" component={SalesReport} />
              <Route exact path="/reports/draws-report" component={DrawsReport} />
              <Route exact path="/reports/players-report" component={PlayersReport} />
              <Route exact path="/reports/transactions-report" component={TransactionsReport} />
              <Route exact path="/reports/winnings-report" component={WinningsReport} />
              <Route exact path="/reports/instant-winnings-report" component={InstantWinningsReport} />
              <Route exact path="/promotions" component={PromotionsPage} />
              <Route exact path="/promotions/promotion/:id" render={() => <PromotionPage isEditMode />} />
              <Route exact path="/promotions/add" component={PromotionPage} />
              <Route
                exact
                path="/tickets"
                render={() => <Tickets key={this.props.location.key} type="default" title="Tickets" />}
              />
              <Route
                exact
                path="/instant-tickets"
                render={() => <Tickets key={this.props.location.key} type="instant" title="Instant Tickets" />}
              />
              <Route exact path="/players/:id" component={Player} />
              <Route exact path="/players" component={PlayersPage} />
              <Route exact path="/lobbies" component={Lobbies} />
              <Route exact path="/lobbies/lobby/:id" component={Lobby} />
              <Route exact path="/lobbies/lobby/:id/published" component={Lobby} />
              <Route exact path="/lobbies/lobby/edit/:id" component={LobbyEdit} />
              <Route exact path="/lobbies/add" component={AddLobby} />
              <Route exact path="/lobbies/:id/versions" component={LobbyVersions} />
              <Route exact path="/lobbies/:id/version/:versionId" component={Lobby} />
              <Route exact path="/configuration/channels" component={OutboundChannels} />
              <Route exact path="/configuration/channels/add" component={OutboundChannel} />
              <Route exact path="/configuration/channels/:id" component={OutboundChannel} />
              {/* we need to pass location key to component so it will re-render and trigger lifecycle methods on page change */}
              <Route exact path="/providers/:name" render={() => <Provider key={this.props.location.key} />} />
              <Route exact path="/providers/:name/add" render={() => <ProviderFormPage edition={false} />} />
              <Route exact path="/providers/:name/:id" render={() => <ProviderFormPage />} />
              <Route exact path="/system" component={SystemPage} />
              <Redirect from="/" to={this.renderRedirectPath()} />
              <Route component={Error404Page} />
            </Switch>
          </div>
        </Site.Wrapper>
        {openNotification && <Notification />}
        <AllModals />
      </div>
    );
  }
}

LoggedLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  user: PropTypes.object,
  userRoles: PropTypes.array,
  // setUserRole: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  getSystemSettings: PropTypes.func.isRequired,
  openNotification: PropTypes.bool,
  location: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }),
};

export default connect(
  (state) => ({
    user: state.user.data,
    userRoles: state.user.data.roles,
    openNotification: state.notification.isOpen,
  }),
  {
    logout,
    getSystemSettings,
  }
)(LoggedLayout);
