import { TOLA } from './tolaConstans';
import { STANDALONE } from './standaloneConstans';
import { EDITEC } from './editecConstans';
import { LTECH } from './ltechConstans';
import { ILS_PLAYER_INTEGRATOR } from './ilsPlayerIntegratorConstans';
import { RNG } from './rngConstans';

export const BETTING_AGENCY = [TOLA];
export const WITH_WINNING_SELECTIONS_CLASS = [STANDALONE, RNG, EDITEC, LTECH];
export const WITH_SCHEDULE = [STANDALONE, RNG, EDITEC, LTECH];
export const WITH_TIMEZONES = [EDITEC];
