import { BTO_BET, BTO_BET_BY_TICKET, BTO_BET_FORM_CONFIG, BTO_BET_COLUMNS } from './data/btoBetConstans';
import { EDITEC, EDITEC_COLUMNS, EDITEC_FORM_CONFIG } from './data/editecConstans';
import { LTECH, LTECH_COLUMNS, LTECH_FORM_CONFIG } from './data/ltechConstans';
import { SINGULAR, SINGULAR_COLUMNS, SINGULAR_FORM_CONFIG } from './data/singularConstans';
import { STANDALONE, STANDALONE_COLUMNS, STANDALONE_FORM_CONFIG } from './data/standaloneConstans';
import { ILS_PLAYER_INTEGRATOR, ILS_PLAYER_INTEGRATOR_COLUMNS, ILS_PLAYER_INTEGRATOR_FORM_CONFIG } from './data/ilsPlayerIntegratorConstans';
import { TOLA, TOLA_COLUMNS, TOLA_FORM_CONFIG } from './data/tolaConstans';
import { BETTING_AGENCY } from './data/commonConstans';
import { RNG, RNG_COLUMNS, RNG_FORM_CONFIG } from './data/rngConstans';

import { buildSelectOptions } from '../select/buildSelectOptions';

/**
 *
 * @param {object[]} array - array with config objects for providerForm
 * @param {string} name - name of the elem that you want to override
 * @param {any} newData - just new data for elem.
 * @returns new config array
 */
const getUpdatedProviderData = (array, name, newData) => {
  const arrCopy = [...array];
  const elem = findElem(arrCopy, name);

  elem.data = buildSelectOptions(newData);

  return arrCopy;
};

/**
 * Simple function searching for elem of array of objects by name.
 * @param {object[]} array - array with objects
 * @param {string} elemName - name of elem you want to find
 * @returns object
 */
const findElem = (array, elemName) => array.find((elem) => elem.name === elemName);

export const getProviderFormBuilderData = (name, additionalData) => {
  switch (name) {
    case BTO_BET_BY_TICKET:
    case BTO_BET:
      return BTO_BET_FORM_CONFIG;
    case ILS_PLAYER_INTEGRATOR:
      return ILS_PLAYER_INTEGRATOR_FORM_CONFIG;
    case EDITEC: {
      const { timezones, availableWinningSelections } = additionalData;

      const withTimezones = getUpdatedProviderData(EDITEC_FORM_CONFIG, 'timezone', timezones);
      const withSelectionClasses = getUpdatedProviderData(
        withTimezones,
        'winningSelectionClass',
        availableWinningSelections
      );

      return withSelectionClasses;
    }
    case TOLA:
      return TOLA_FORM_CONFIG;
    case LTECH: {
      const { availableWinningSelections } = additionalData;

      const withSelectionClasses = getUpdatedProviderData(
        LTECH_FORM_CONFIG,
        'winningSelectionClass',
        availableWinningSelections
      );

      return withSelectionClasses;
    }
    case SINGULAR:
      return SINGULAR_FORM_CONFIG;
    case RNG: {
      const { availableWinningSelections } = additionalData;

      const withSelectionClasses = getUpdatedProviderData(
        RNG_FORM_CONFIG,
        'winningSelectionClass',
        availableWinningSelections
      );

      return withSelectionClasses;
    }
    case STANDALONE: {
      const { availableWinningSelections } = additionalData;

      const withSelectionClasses = getUpdatedProviderData(
        STANDALONE_FORM_CONFIG,
        'winningSelectionClass',
        availableWinningSelections
      );

      return withSelectionClasses;
    }

    default:
      return [];
  }
};

export const getProvidersTableColumns = (name) => {
  switch (name) {
    case BTO_BET_BY_TICKET:
    case BTO_BET:
      return BTO_BET_COLUMNS;
    case ILS_PLAYER_INTEGRATOR:
      return ILS_PLAYER_INTEGRATOR_COLUMNS;
    case EDITEC:
      return EDITEC_COLUMNS;
    case TOLA:
      return TOLA_COLUMNS;
    case LTECH:
      return LTECH_COLUMNS;
    case SINGULAR:
      return SINGULAR_COLUMNS;
    case RNG:
      return RNG_COLUMNS;
    case STANDALONE:
      return STANDALONE_COLUMNS;
    default:
      return [];
  }
};

export const getProperApiUrl = (name, id = null) => {
  const isAgency = BETTING_AGENCY.includes(name);
  const provider = isAgency ? 'betting-agency' : 'game';
  const properId = id ? `/${id}` : '';

  const providerName = name === BTO_BET_BY_TICKET ? BTO_BET : name;

  return `/api/v1/${providerName}-${provider}-configuration${properId}`;
};
