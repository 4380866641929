export const ILS_PLAYER_INTEGRATOR = 'ils-player-integrator';

export const ILS_PLAYER_INTEGRATOR_COLUMNS = [
  {
    header: 'Name',
    type: 'string',
    accessor: 'name',
  },
  {
    header: 'URL',
    type: 'string',
    accessor: 'apiUrl',
  },
  {
    header: 'Actions',
    accessor: 'actions',
    type: 'actions',
    id: 'id',
    elements: [
      {
        header: 'Actions',
        type: 'edit',
        linkto: `/providers/${ILS_PLAYER_INTEGRATOR}/:id`,
        txt: 'Edit',
      },
    ],
  },
];

export const ILS_PLAYER_INTEGRATOR_FORM_CONFIG = [
  {
    name: 'name',
    title: 'Name',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'apiUrl',
    title: 'API URL',
    type: 'url',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'apiBearerToken',
    title: 'Api Token',
    type: 'text',
    isRequired: true,
    maxLength: null,
    minLength: 0,
  },
  {
    name: 'brand',
    title: 'Brand',
    type: 'search-brand',
    data: [],
  },
];
